export const environment = {
  production: true,
  hmr: false,
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/dashboard',
    redirectToWhenUnauthenticated: '/auth/login',
  },
  apiKey: '1vfF9GDw7t4yndtmsyOHnvi8bxXYdgXO5EdFX9lT7wg=',
  api: {
    wsUrl: 'wss://api-develop.wec-cabos.ligafacens.com/ws',
    baseUrl: 'https://api-develop.wec-cabos.ligafacens.com',
  },
};
